<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 07:39:52
 * @ Description: View, edit and add calendar events overlay.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="showCalendarEeventOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        width="430px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            height="420px"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-form
                    v-model="validEventForm"
                    ref="eventForm"
                    style="height: 100%;"
                >
                    <v-row
                        style="height: 100%;"
                    >
                        <!-- Event Details -->
                        <v-col
                            cols="12"
                            style="min-height: calc(100% + 12px); background-color: rgba(255, 168, 88, 0.25);"
                        >
                            <v-row
                                class="px-9 py-3"
                            >
                                <v-col
                                    cols="12"
                                    class="os-18-sb text-center"
                                >
                                    {{ actionHeading }} Calendar Event:
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="pb-0 os-15-sb"
                                >
                                    Event Description<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-text-field
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="eventToEdit.summary"
                                        counter="50"
                                        :rules="nameRules"
                                        :disabled="action === 'view'"
                                    />
                                </v-col>
                                <v-col
                                    cols="7"
                                    class="py-0 os-15-sb pr-1"
                                >
                                    Start Date<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-menu
                                        v-model="startDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                color="#2E567A"
                                                class="pt-2 os-13-r"
                                                background-color="white"
                                                v-model="startDate"
                                                :rules="startDateTimeRules"
                                                v-bind="attrs"
                                                v-on="on"
                                                :append-icon="mdiClose"
                                                @click:append="startDate = null"
                                                :disabled="action === 'view'"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="startDate"
                                            no-title
                                            scrollable
                                            color="#2E567A"
                                            @input="startDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="5"
                                    class="py-0 os-15-sb pl-1"
                                >
                                    Start Time<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-autocomplete
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="startTime"
                                        :items="startTimeItems"
                                        :error="startTimeError"
                                        :disabled="action === 'view'"
                                    />
                                </v-col>
                                <v-col
                                    cols="7"
                                    class="py-0 os-15-sb pr-1"
                                >
                                    End Date<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-menu
                                        v-model="endDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                color="#2E567A"
                                                class="pt-2 os-13-r"
                                                background-color="white"
                                                v-model="endDate"
                                                :rules="endDateTimeRules"
                                                v-bind="attrs"
                                                v-on="on"
                                                :append-icon="mdiClose"
                                                @click:append="endDate = null"
                                                :disabled="action === 'view'"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="endDate"
                                            no-title
                                            scrollable
                                            color="#2E567A"
                                            @input="endDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="5"
                                    class="py-0 os-15-sb pl-1"
                                >
                                    End Time<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-autocomplete
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="endTime"
                                        :items="endTimeItems"
                                        :error="endTimeError"
                                        :disabled="action === 'view'"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="pb-0 os-13-sb"
                                    :class="action === 'view' ? 'text-right' : 'text-center'"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb"
                                        :class="action !== 'view' ? 'mr-4' : ''"
                                        color="#2E567A"
                                        @click="toggleCalendarEventOverlayComponent(false)"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb"
                                        color="#FFA858"
                                        @click="toggleCalendarEventOverlayComponent(true)"
                                        :disabled="!validEventForm"
                                        v-if="action !== 'view'"
                                    >
                                        Schedule
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Event Details -->
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="showCalendarEeventOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
        max-width="315px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            max-height="450px"
        >
            <v-card-text
                class="pa-3"
                style="color: black;"
            >
                <v-form
                    v-model="validEventForm"
                    ref="eventForm"
                >
                    <v-row
                        style="height: 100%;"
                    >
                        <!-- Event Details -->
                        <v-col
                            cols="12"
                            style="background-color: rgba(255, 168, 88, 0.25);"
                        >
                            <v-row
                                class="py-3"
                            >
                                <v-col
                                    cols="12"
                                    class="os-18-sb text-center"
                                >
                                    {{ actionHeading }} Calendar Event:
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="pb-0 os-15-sb"
                                >
                                    Event Description<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-text-field
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="eventToEdit.summary"
                                        counter="50"
                                        :rules="nameRules"
                                        :disabled="action === 'view'"
                                    />
                                </v-col>
                                <v-col
                                    :cols="$store.state.general.customBreakpoints.xxs ? 12 : 7"
                                    :class="$store.state.general.customBreakpoints.xxs ? '' : 'pr-1'"
                                    class="py-0 os-15-sb"
                                >
                                    Start Date<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-menu
                                        v-model="startDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                color="#2E567A"
                                                class="pt-2 os-13-r"
                                                background-color="white"
                                                v-model="startDate"
                                                :rules="startDateTimeRules"
                                                v-bind="attrs"
                                                v-on="on"
                                                :append-icon="mdiClose"
                                                @click:append="startDate = null"
                                                :disabled="action === 'view'"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="startDate"
                                            no-title
                                            scrollable
                                            color="#2E567A"
                                            @input="startDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    :cols="$store.state.general.customBreakpoints.xxs ? 12 : 5"
                                    :class="$store.state.general.customBreakpoints.xxs ? '' : 'pl-1'"
                                    class="py-0 os-15-sb"
                                >
                                    Start Time<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-autocomplete
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="startTime"
                                        :items="startTimeItems"
                                        :error="startTimeError"
                                        :disabled="action === 'view'"
                                    />
                                </v-col>
                                <v-col
                                    :cols="$store.state.general.customBreakpoints.xxs ? 12 : 7"
                                    :class="$store.state.general.customBreakpoints.xxs ? '' : 'pr-1'"
                                    class="py-0 os-15-sb"
                                >
                                    End Date<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-menu
                                        v-model="endDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                color="#2E567A"
                                                class="pt-2 os-13-r"
                                                background-color="white"
                                                v-model="endDate"
                                                :rules="endDateTimeRules"
                                                v-bind="attrs"
                                                v-on="on"
                                                :append-icon="mdiClose"
                                                @click:append="endDate = null"
                                                :disabled="action === 'view'"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="endDate"
                                            no-title
                                            scrollable
                                            color="#2E567A"
                                            @input="endDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    :cols="$store.state.general.customBreakpoints.xxs ? 12 : 5"
                                    :class="$store.state.general.customBreakpoints.xxs ? '' : 'pl-1'"
                                    class="py-0 os-15-sb"
                                >
                                    End Time<span v-if="action !== 'view'" style="color: red;">*</span>
                                    <br />
                                    <v-autocomplete
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="endTime"
                                        :items="endTimeItems"
                                        :error="endTimeError"
                                        :disabled="action === 'view'"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-13-sb"
                                    :class="action === 'view' ? 'text-right' : 'text-center'"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb"
                                        :class="action !== 'view' ? 'mr-4' : ''"
                                        color="#2E567A"
                                        @click="toggleCalendarEventOverlayComponent(false)"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb"
                                        color="#FFA858"
                                        @click="toggleCalendarEventOverlayComponent(true)"
                                        :disabled="!validEventForm"
                                        v-if="action !== 'view'"
                                    >
                                        Schedule
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Event Details -->
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    import { mdiClose } from '@mdi/js'

    const moment = require('moment')

    export default {
        name: 'CalendarViewEditEventOverlayComponent',

        props: {
            showCalendarEeventOverlayBoolean: {
                type: Boolean,
                required: true
            },

            event: {
                type: Object
            },

            action: {
                type: String,
                required: true,
                default: 'view'
            }
        },

        watch: {
            showCalendarEeventOverlayBoolean: {
                handler (value) {
                    if (value) {
                        if (this.event) {
                            this.eventToEdit.summary = this.event.title

                            const dateTimeFormat = "YYYY-MM-DD HH:mm"

                            let startDate
                            if (this.event.start._date) startDate = moment(this.event.start._date).format(dateTimeFormat)
                            else startDate = moment(this.event.start).format(dateTimeFormat)
                            const splitStartDate = startDate.split(' ')

                            this.startDate = splitStartDate[0]
                            this.startTime = splitStartDate[1]

                            let endDate
                            if (this.event.end._date) endDate = moment(this.event.end._date).format(dateTimeFormat)
                            else endDate = moment(this.event.end).format(dateTimeFormat)
                            const splitEndDate = endDate.split(' ')

                            this.endDate = splitEndDate[0]
                            this.endTime = splitEndDate[1]
                        }
                    } else {
                        this.eventToEdit = {
                            summary: '',
                            start_date_time: null,
                            end_date_time: null
                        }

                        this.startDate = null
                        this.startTime = null
                        this.endDate = null
                        this.endTime = null
                        this.$refs.eventForm.resetValidation()
                    }
                }
            },

            startDate: {
                handler (value) {
                    if (value && this.startTime) this.eventToEdit.start_date_time = value + ' ' + this.startTime
                    else if (!value) this.eventToEdit.start_date_time = null
                },
                immediate: true
            },

            startTime: {
                handler (value) {
                    if (value && this.startDate) this.eventToEdit.start_date_time = this.startDate + ' ' + value
                    else if (!value) this.eventToEdit.start_date_time = null
                },
                immediate: true
            },

            endDate: {
                handler (value) {
                    if (value && this.endTime) this.eventToEdit.end_date_time = value + ' ' + this.endTime
                    else if (!value) this.eventToEdit.end_date_time = null
                },
                immediate: true
            },

            endTime: {
                handler (value) {
                    if (value && this.endDate) this.eventToEdit.end_date_time = this.endDate + ' ' + value
                    else if (!value) this.eventToEdit.end_date_time = null
                },
                immediate: true
            }
        },

        computed: {
            nameRules () { 
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 50 || 'Max 50 characters'
                ]
            },

            startDateTimeRules () {
                let today = new Date()
                today = today.toISOString()

                let start = new Date(this.eventToEdit.start_date_time)
                start = start.toISOString()

                if (this.startDate === null) return [ 'Required field' ]
                else if (this.startTime === null) return [ 'Select start time' ]
                else if (this.eventToEdit.start_date_time.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                else if (start.replace(/-/g, '/') < today.replace(/-/g, '/')) return [ 'Start cannot be before now' ]
                else if (this.eventToEdit.end_date_time !== null && new Date(this.eventToEdit.start_date_time.replace(/-/g, '/')).getTime() > new Date(this.eventToEdit.end_date_time.replace(/-/g, '/')).getTime()) return [ 'Start must be before end' ]
                else return []
            },

            endDateTimeRules () {
                if (this.endDate === null) return [ 'Required field' ]
                else if (this.endTime === null) return [ 'Select end time' ]
                else if (this.eventToEdit.end_date_time.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                else if (this.eventToEdit.start_date_time !== null && new Date(this.eventToEdit.end_date_time.replace(/-/g, '/')).getTime() < new Date(this.eventToEdit.start_date_time.replace(/-/g, '/')).getTime()) return [ 'End must be after start' ]
                else return []
            },

            startTimeError () {
                if (this.startDate && !this.startTime) return true
                else return false
            },
            
            endTimeError () {
                if (this.endDate && !this.endTime) return true
                else return false
            },

            startTimeItems () {
                let timeItems = []

                for (let index = 0; index < 24; index++) {
                    let hour
                    if (index < 10) hour = `0${index}:00`
                    else hour = `${index}:00`

                    timeItems.push(hour)

                    let halfHour
                    if (index < 10) halfHour = `0${index}:30`
                    else halfHour = `${index}:30`

                    timeItems.push(halfHour)
                }

                return timeItems
            },

            endTimeItems () {
                let timeItems = []

                for (let index = 0; index < 24; index++) {
                    let hour
                    if (index < 10) hour = `0${index}:00`
                    else hour = `${index}:00`

                    timeItems.push(hour)

                    let halfHour
                    if (index < 10) halfHour = `0${index}:30`
                    else halfHour = `${index}:30`

                    timeItems.push(halfHour)
                }

                return timeItems
            },

            actionHeading () {
                if (this.action === 'view') return 'View'
                else if (this.action === 'add') return 'Schedule'
                else return 'Update'
            }
        },

        data () {
            return {
                eventToEdit: {
                    summary: '',
                    start_date_time: null,
                    end_date_time: null
                },
                validEventForm: false,
                startDateMenu: false,
                endDateMenu: false,
                mdiClose,
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null
            }
        },

        methods: {
            toggleCalendarEventOverlayComponent (confirmEvent) {
                this.$emit('toggleCalendarEventOverlayComponent', false, confirmEvent, this.eventToEdit)
            }
        }
    }
</script>
<style scoped>
    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
