<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-11 18:23:12
 * @ Description: Fixer's calendar page in the user's dashboard section of the app.
 -->

<template>
    <div>
        <!-- Desktop -->
        <v-row
            v-if="$vuetify.breakpoint.mdAndUp"
            justify="center"
        >
            <v-col
                cols="12"
                :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
            >
                <v-row
                    style="height: calc(100vh - 190px); max-height: calc(100vh - 190px);"
                >
                    <!-- Heading & Add Event Button -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                        style="position: relative;"
                    >
                        <!-- <v-btn
                            style="position: absolute; right: 16px; top: 9px;"
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                        >
                            <span>Add Event</span>
                            <v-icon
                                class="ml-1"
                            >
                                {{ mdiPlusCircleOutline }}
                            </v-icon>
                        </v-btn> -->
                        Calendar
                    </v-col>
                    <!-- Heading & Add Event Button -->

                    <!-- Left Calendar Display Options -->
                    <v-col
                        cols="3"
                    >
                        <v-row>
                            <!-- Current Date Range -->
                            <v-col
                                cols="12"
                                class="os-13-sb"
                                style="color: #8F9BB3;"
                            >
                                {{ calendarRenderRangeDates }}
                            </v-col>
                            <!-- Current Date Range -->

                            <!-- Event Types -->
                            <v-col
                                cols="12"
                                class="os-17-sb"
                            >
                                Event Types
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-checkbox
                                    v-for="eventType in eventTypes"
                                    :key="eventType.id"
                                    :label="eventType.name"
                                    :color="eventType.bgColor"
                                    v-model="eventType.visible"
                                    @change="toggleEventTypeVisibility(eventType)"
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <!-- Event Types -->

                            <!-- Calendar Options -->
                            <v-col
                                cols="12"
                                class="os-17-sb"
                            >
                                Calendar Options
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-checkbox
                                    label="Show Weekends"
                                    v-model="showWeekends"
                                    @change="toggleShowWeekends"
                                    dense
                                    hide-details
                                    color="#8F9BB3"
                                />
                                <v-checkbox
                                    label="Start Week on Monday"
                                    v-model="startMonday"
                                    @change="toggleStartMonday"
                                    dense
                                    hide-details
                                    color="#8F9BB3"
                                />
                            </v-col>
                            <!-- Calendar Options -->
                        </v-row>
                    </v-col>
                    <!-- Left Calendar Display Options -->

                    <!-- Top Calendar Display Options & Calendar -->
                    <v-col
                        cols="9"
                        style="height: 100%; max-height: 100%; position: relative;"
                    >
                        <!-- Today Button -->
                        <v-btn
                            style="position: absolute; top: -48px;"
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            @click="goToToday"
                        >
                            Today
                        </v-btn>
                        <!-- Today Button -->

                        <!-- Prev/Next Range Buttons -->
                        <v-btn
                            style="position: absolute; top: -48px; left: 90px; min-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="list"
                            color="#2E567A"
                            tile
                            class="rounded-l-lg"
                            @click="goToPreviousCalendarRange"
                        >
                            <v-icon
                                color="white"
                            >
                                {{ mdiChevronLeft }}
                            </v-icon>
                        </v-btn>
                        <v-btn
                            style="position: absolute; top: -48px; left: 144px; in-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="grid"
                            color="#2E567A"
                            tile
                            class="rounded-r-lg"
                            @click="goToNextCalendarRange"
                        >
                            <v-icon
                                color="white"
                            >
                                {{ mdiChevronRight }}
                            </v-icon>
                        </v-btn>
                        <!-- Prev/Next Range Buttons -->

                        <!-- Selected Range -->
                        <v-select
                            style="position: absolute; top: -47px; left: 202px; height: 36px;"
                            dense
                            outlined
                            color="#2E567A"
                            class="os-13-r"
                            background-color="white"
                            hide-details
                            placeholder="Calendar View"
                            :items="calendarViewOptions"
                            v-model="selectedCalendarViewOption"
                            item-color="#2E567A"
                            @change="changeCalendarView"
                        />
                        <!-- Selected Range -->

                        <!-- Calendar -->
                        <div
                            id="calendar"
                            style="height: 100%; max-height: 100%; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;"
                        />
                        <!-- Calendar -->
                    </v-col>
                    <!-- Top Calendar Display Options & Calendar -->
                </v-row>
            </v-col>
        </v-row>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-row
            v-else-if="$vuetify.breakpoint.smAndDown"
            justify="center"
        >
            <v-col
                cols="12"
                :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
            >
                <v-row
                    :style="$vuetify.breakpoint.sm ? 'height: calc(100vh - 125px); max-height: calc(100vh - 125px);' : ''"
                >
                    <!-- Heading & Add Event Button -->
                    <!-- <v-col
                        cols="12"
                        class="os-22-sb"
                        style="position: relative;"
                    >
                        <v-btn
                            style="position: absolute; right: 16px; top: 12px;"
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                        >
                            <span>Add Event</span>
                            <v-icon
                                class="ml-1"
                            >
                                {{ mdiPlusCircleOutline }}
                            </v-icon>
                        </v-btn>
                        Calendar
                    </v-col> -->
                    <!-- Heading & Add Event Button -->

                    <!-- Calendar Display Options -->
                    <v-col
                        cols="12"
                        sm="4"
                        class="pr-sm-0 pt-sm-2"
                    >
                        <v-row>
                            <!-- Current Date Range -->
                            <v-col
                                cols="12"
                                class="os-13-sb pb-sm-0"
                                style="color: #8F9BB3;"
                                v-if="$vuetify.breakpoint.sm"
                            >
                                {{ calendarRenderRangeDates }}
                            </v-col>
                            <!-- Current Date Range -->

                            <!-- Today Button -->
                            <v-col
                                :cols="$store.state.general.customBreakpoints.xxs ? 6 : 3"
                                sm="6"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    style="max-width: 100%;"
                                    :block="$vuetify.breakpoint.sm"
                                    @click="goToToday"
                                >
                                    Today
                                </v-btn>
                            </v-col>
                            <!-- Today Button -->

                            <v-spacer />

                            <!-- Prev/Next Range Buttons -->
                            <v-col
                                cols="3"
                                class="text-center"
                            >
                                <v-btn
                                    style="max-width: 100%; opacity: 0.5;"
                                    icon
                                    @click="goToPreviousCalendarRange"
                                >
                                    <v-icon
                                        color="#2E567A"
                                    >
                                        {{ mdiChevronLeft }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col
                                cols="3"
                                class="text-center"
                            >
                                <v-btn
                                    style="max-width: 100%; opacity: 0.5;"
                                    icon
                                    @click="goToNextCalendarRange"
                                >
                                    <v-icon
                                        color="#2E567A"
                                    >
                                        {{ mdiChevronRight }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <!-- Prev/Next Range Buttons -->

                            <!-- Event Types -->
                            <v-col
                                cols="6"
                                sm="12"
                                class="os-17-sb"
                            >
                                Event Types
                            </v-col>
                            <!-- Current Date Range -->
                            <v-col
                                cols="6"
                                class="os-13-sb text-right"
                                style="color: #8F9BB3;"
                                v-if="$vuetify.breakpoint.xs"
                                align-self="center"
                            >
                                {{ calendarRenderRangeDates }}
                            </v-col>
                            <!-- Current Date Range -->
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="6"
                                sm="12"
                                v-for="eventType in eventTypes"
                                :key="eventType.id"
                                class="py-0"
                            >
                                <v-checkbox
                                    :label="eventType.name"
                                    :color="eventType.bgColor"
                                    v-model="eventType.visible"
                                    @change="toggleEventTypeVisibility(eventType)"
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <!-- Event Types -->
                        </v-row>
                    </v-col>
                    <!-- Calendar Display Options -->

                    <!-- Calendar -->
                    <v-col
                        cols="12"
                        sm="8"
                        class="pt-2"
                        style="height: 100%; max-height: 100%;"
                    >
                        <div
                            id="calendar"
                            style="height: 100%; max-height: 100%; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;"
                        />
                    </v-col>
                    <!-- Calendar -->
                </v-row>
            </v-col>
        </v-row>
        <!-- Mobile -->

        <!-- Add/Edit/View Calendar Event Overlay -->
        <calendar-view-edit-event-overlay-component
            @toggleCalendarEventOverlayComponent="toggleCalendarEventOverlayComponent"
            :showCalendarEeventOverlayBoolean="showCalendarEeventOverlayBoolean"
            :event="selectedEvent"
            :action="action"
        />
        <!-- Add/Edit/View Calendar Event Overlay -->
    </div>
</template>

<script>
    import Calendar from 'tui-calendar'
    import 'tui-calendar/dist/tui-calendar.css'
    import { GeneralCalendarController } from '@/logic/controller/index.controller.js'
    import { EventModel, EventTypeModel } from '@/logic/model/index.model.js'
    import { mdiPlusCircleOutline, mdiChevronLeft, mdiChevronRight } from '@mdi/js'
    import CalendarViewEditEventOverlayComponent from '@/overlays/calendar/CalendarViewEditEventOverlayComponent.vue'

    const moment = require('moment')

    export default {
        name: 'CalendarPage',

        components: {
            CalendarViewEditEventOverlayComponent
        },

        watch: {
            '$vuetify.breakpoint': {
                handler (value, oldValue) {
                    if (value !== oldValue) {
                        if (value.smAndDown) {
                            this.selectedCalendarViewOption = 'Daily'
                        } else {
                            this.selectedCalendarViewOption = 'Weekly'
                        }

                        this.$nextTick(() => {
                            this.changeCalendarView()
                        })
                    } else {
                        if (value.smAndDown) {
                            this.selectedCalendarViewOption = 'Daily'
                        }
                    }
                },
                deep: true,
                immediate: true
            },

            '$route.query.event_id': {
                handler (value) {
                    if (value) {
                        this.goToQueryDate()
                    }
                }
            }
        },

        data () {
            return {
                mdiPlusCircleOutline,
                mdiChevronLeft,
                mdiChevronRight,
                calendar: null,
                dummyEventTypes: [
                    {
                        id: 1,
                        name: 'Interview',
                        color: '#FFA858'
                    },
                    {
                        id: 2,
                        name: 'Job',
                        color: '#2E567A'
                    },
                    // {
                    //     id: 3,
                    //     name: 'Project',
                    //     color: '#0091FF'
                    // },
                    // {
                    //     id: 4,
                    //     name: 'Tender',
                    //     color: '#44D7B6'
                    // },
                    // {
                    //     id: 5,
                    //     name: 'Service',
                    //     color: '#57AA00'
                    // }
                ],
                eventTypes: [],
                calendarViewOptions: [
                    'Daily',
                    'Weekly',
                    'Monthly'
                ],
                selectedCalendarViewOption: 'Weekly',
                calendarOptions: {},
                showWeekends: true,
                startMonday: false,
                calendarRenderRangeDates: '',
                calendarEvents: [],
                currentCalendarView: 'week',
                action: 'view',
                selectedEvent: {},
                eventDates: null,
                lastSelectedCalendarSlot: null,
                generalCalendarController: null,
                showCalendarEeventOverlayBoolean: false
            }
        },

        beforeMount () {
            this.generalCalendarController = new GeneralCalendarController()
        },

        async mounted () {
            var self = this

            this.calendar = new Calendar('#calendar', {
                defaultView: self.currentCalendarView,
                calendars: this.eventTypes,
                template: {
                    milestone: function (event) {
                        return '<span class="calendar-font-icon ic-milestone-b"></span> <span style="background-color: ' + event.bgColor + '">' + event.title + '</span>'
                    },
                    allday: function (event) {
                        return self.getTimeTemplate(event, true)
                    },
                    time: function (event) {
                        return self.getTimeTemplate(event, false)
                    }
                },
                taskView: ['allday']
            })

            this.calendar.on({
                clickSchedule: function (e) {
                    if (!e.schedule.isAllDay) {
                        self.action = 'view'
                        self.selectedEvent = e.schedule
                        self.showCalendarEeventOverlayBoolean = true
                    } else if (e.schedule.raw.work_type_id === 3 && e.schedule.raw.work_id) {
                        self.$router.push(`/job/${e.schedule.raw.work_id}/view`)
                    }
                },
                // beforeCreateSchedule: function (e) {
                //     self.action = 'add'
                //     self.eventDates = {
                //         start: e.start._date,
                //         end: e.end._date
                //     }
                //     self.showCalendarEeventOverlayBoolean = true
                //     self.lastSelectedCalendarSlot = e
                // }
            })

            await this.getCalendarEvents()
            await this.getCalendarEventTypes()

            this.setSchedules()

            this.calendarOptions = this.calendar.getOptions()
            this.setCalendarRenderRangeDates()

            if (this.$route.query.event_id) {
                this.goToQueryDate()
            }
        },

        methods: {
            toggleCalendarEventOverlayComponent (value, confirmEvent, event) {
                if (!value && this.$route.query.event_id) this.$router.replace({ path: '/calendar' })
                this.showCalendarEeventOverlayBoolean = value

                // if (confirmEvent) this.upsertCalendarEvebnt(event)
            },

            async getCalendarEventTypes () {
                if (this.dummyEventTypes && this.dummyEventTypes.length > 0) {
                    for (let index = 0; index < this.dummyEventTypes.length; index++) {
                        const eventType = this.dummyEventTypes[index]
                        this.eventTypes.push(new EventTypeModel(eventType))
                    }
                }
            },

            async getCalendarEvents () {
                try {
                    const response = await this.generalCalendarController.retrieve()
                    if (response.meeting_event && response.meeting_event.length > 0) {
                        for (let index = 0; index < response.meeting_event.length; index++) {
                            const event = response.meeting_event[index]
                            this.calendarEvents.push(new EventModel(event))
                        }
                    }

                    if (response.day_event && response.day_event.length > 0) {
                        for (let index = 0; index < response.day_event.length; index++) {
                            const event = response.day_event[index]
                            this.calendarEvents.push(new EventModel(event))
                        }
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            getTimeTemplate (event, isAllDay) {
                var eventDisplayText = []
                var start = moment(event.start.toUTCString())
                if (!isAllDay) {
                    eventDisplayText.push('<strong>' + start.format('HH:mm') + '</strong> ')
                }

                if (event.isPrivate) {
                    eventDisplayText.push('<span class="calendar-font-icon ic-lock-b"></span>')
                    eventDisplayText.push(' Private')
                } else {
                    if (event.isReadOnly) {
                        eventDisplayText.push('<span class="calendar-font-icon ic-readonly-b"></span>')
                    } else if (event.recurrenceRule) {
                        eventDisplayText.push('<span class="calendar-font-icon ic-repeat-b"></span>')
                    } else if (event.attendees.length) {
                        eventDisplayText.push('<span class="calendar-font-icon ic-user-b"></span>')
                    } else if (event.location) {
                        eventDisplayText.push('<span class="calendar-font-icon ic-location-b"></span>')
                    }
                    eventDisplayText.push(' ' + event.title)
                }

                return eventDisplayText.join('')
            },

            async setSchedules () {
                await this.calendar.clear()
                this.calendar.createSchedules(this.calendarEvents)
            },

            toggleEventTypeVisibility (eventType) {
                this.calendar.toggleSchedules(eventType.id, !eventType.visible, false)
                this.calendar.render(true)
            },

            goToToday () {
                this.calendar.today()
                this.setCalendarRenderRangeDates()
            },

            goToQueryDate () {
                const eventIndex = this.calendarEvents.findIndex(itemToFind => itemToFind.id === Number(this.$route.query.event_id))
                if (eventIndex > -1) {
                    this.calendar.setDate(new Date(this.calendarEvents[eventIndex].start))

                    this.selectedEvent = this.calendarEvents[eventIndex]
                    this.toggleCalendarEventOverlayComponent(true)
                }
            },

            goToPreviousCalendarRange () {
                this.calendar.prev()
                this.setCalendarRenderRangeDates()
            },

            goToNextCalendarRange () {
                this.calendar.next()
                this.setCalendarRenderRangeDates()
            },

            changeCalendarView () {
                switch (this.selectedCalendarViewOption) {
                case 'Daily':
                    this.currentCalendarView = 'day'
                    break
                case 'Weekly':
                    this.currentCalendarView = 'week'
                    break
                case 'Monthly':
                    this.calendarOptions.month.visibleWeeksCount = 0
                    this.currentCalendarView = 'month'
                    break
                case '2 weeks':
                    this.calendarOptions.month.visibleWeeksCount = 2
                    this.currentCalendarView = 'month'
                    break
                case '3 weeks':
                    this.calendarOptions.month.visibleWeeksCount = 3
                    this.currentCalendarView = 'month'
                    break
                default:
                    break
                }

                this.calendar.setOptions(this.calendarOptions, true)
                this.calendar.changeView(this.currentCalendarView, true)
                this.setCalendarRenderRangeDates()
            },

            toggleShowWeekends () {
                this.calendarOptions.month.workweek = !this.calendarOptions.month.workweek
                this.calendarOptions.week.workweek = !this.calendarOptions.week.workweek

                this.calendar.setOptions(this.calendarOptions, true)
                this.calendar.changeView(this.currentCalendarView, true)
                this.setCalendarRenderRangeDates()
            },

            toggleStartMonday () {
                this.calendarOptions.month.startDayOfWeek = this.calendarOptions.month.startDayOfWeek ? 0 : 1
                this.calendarOptions.week.startDayOfWeek = this.calendarOptions.week.startDayOfWeek ? 0 : 1

                this.calendar.setOptions(this.calendarOptions, true)
                this.calendar.changeView(this.currentCalendarView, true)
                this.setCalendarRenderRangeDates()
            },

            setCalendarRenderRangeDates () {
                var options = this.calendar.getOptions()

                var eventDisplayText = []
                if (this.currentCalendarView === 'day') {
                    eventDisplayText.push(this.getCurrentCalendarDate('DD/MM/YYYY'))
                } else if (this.currentCalendarView === 'month' &&
                    (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)) {
                    eventDisplayText.push(this.getCurrentCalendarDate('MM/YYYY'))
                } else {
                    eventDisplayText.push(moment(this.calendar.getDateRangeStart().getTime()).format('DD/MM/YYYY'))
                    eventDisplayText.push(' - ')
                    eventDisplayText.push(moment(this.calendar.getDateRangeEnd().getTime()).format('DD/MM/YYYY'))
                }

                this.calendarRenderRangeDates = eventDisplayText.join('')
            },

            getCurrentCalendarDate (format) {
                var currentDate = moment([this.calendar.getDate().getFullYear(), this.calendar.getDate().getMonth(), this.calendar.getDate().getDate()])

                return currentDate.format(format)
            }
        }
    }
</script>
<style>
    @media only screen and (min-width: 601px) {
        .tui-full-calendar-week-container {
            min-height: 100% !important;
        }
    }

    .tui-full-calendar-month {
        min-height: 100% !important;
    }

    .tui-full-calendar-dayname.tui-full-calendar-holiday-sun .tui-full-calendar-dayname-date-area {
        color: #FFA858 !important;
    }

    .tui-full-calendar-month-dayname-item .tui-full-calendar-holiday-sun {
        color: #FFA858 !important;
    }

    .tui-full-calendar-weekday-grid-line.tui-full-calendar-holiday-sun.tui-full-calendar-near-month-day.tui-full-calendar-extra-date .tui-full-calendar-weekday-grid-header span {
        color: #FFA858 !important;
        opacity: 0.6 !important;
    }

    .tui-full-calendar-weekday-grid-line.tui-full-calendar-holiday-sun.tui-full-calendar-near-month-day .tui-full-calendar-weekday-grid-header span {
        color: #FFA858 !important;
    }

    .tui-full-calendar-weekday-grid-date.tui-full-calendar-weekday-grid-date-decorator {
        background: #2E567A !important;
    }

    .tui-full-calendar-timegrid-hourmarker-time {
        color: #2E567A !important;
    }

    .tui-full-calendar-timegrid-hourmarker-line-left {
        border-top: 1px dashed #2E567A !important;
    }

    .tui-full-calendar-timegrid-hourmarker-line-today {
        border-top: 1px solid #2E567A !important;
    }

    .tui-full-calendar-timegrid-todaymarker {
        background-color: #2E567A !important;
    }
</style>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }
</style>
